'use client';

import {
	BigNum,
	isVariant,
	MarketType,
	PositionDirection,
} from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	MarketId,
	matchEnum,
	UIMarket,
	UISerializableOrder,
} from '@drift/common';
import { Edit, Close } from '@drift-labs/icons';
import { useCallback, useEffect, useState } from 'react';
import { useTickedAccountData } from 'src/hooks/useAccountData';
import useDriftActions from 'src/hooks/useDriftActions';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { LPOpenOrderData } from 'src/hooks/useOpenOrdersData';
import useDevSwitchIsOn from '../hooks/useDevSwitchIsOn';
import useShowAccountValues from '../hooks/useShowAccountValues';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import Button from './Button';
import TableActionButton from './Buttons/TableActionButton';
import EditOrderPopup from './Popups/EditOrderPopup';
import TableV2 from './Tables/TableV2';
import TableStateWrapper from './TableStateWrapper';
import Text from './Text/Text';
import Tooltip from './Tooltip/Tooltip';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import { twMerge } from 'tailwind-merge';
import UI_UTILS from 'src/utils/uiUtils';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { MAX_PREDICTION_PRICE_BIGNUM } from 'src/constants/math';
import { useMarketStepSize } from 'src/hooks/useMarketStepSize';
import PositionsAndOrdersFilterDropdown, {
	PositionsAndOrdersFilterOption,
} from './Utils/PositionAndOrdersFiltersDropdown';
import { useTableFiltersState } from 'src/hooks/useTableFiltersState';
import NumberDisplayV3 from './Utils/NumberDisplayV3';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';

export type AccountOrder = UISerializableOrder & { accountName?: string };
export type AccountLPOrder = LPOpenOrderData & { accountName?: string };

const EMPTY_ORDERS_STATE: Array<any> = [];

const OpenPerpOrderRow = ({
	order,
	isOverviewPage,
	tableGrid,
	showFillButton,
	showAccountValues,
	orderLimitPrice,
}: {
	order: AccountOrder;
	isOverviewPage: boolean;
	tableGrid: string;
	showFillButton: boolean;
	showAccountValues: boolean;
	orderLimitPrice: BigNum;
}) => {
	const setStore = useDriftStore((s) => s.set);
	const isMobile = useIsMobileScreenSize();
	const actions = useDriftActions();

	const marketId = new MarketId(order.marketIndex, order.marketType);
	const stepSize = useMarketStepSize(marketId);

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen: setShowEditOrderPopup,
		isPopoverOpen: showEditOrderPopup,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: isOverviewPage ? 'left' : 'left-end',
		},
		{ disableAutoPlacement: true }
	);

	const handleClickMarket = useCallback(
		(marketIndex: number, marketType: MarketType) => {
			actions.switchMarket({
				marketIndex,
				marketType,
				goToTradePage: true,
			});
		},
		[]
	);

	// Mobile only
	const handleShowEditOrderModal = () => {
		setStore((s) => {
			s.popups.editOrderPopupOptions.orderToEdit = order;
			s.modals.showEditOrderModal = true;
		});
	};

	const uiMarket = new UIMarket(order.marketIndex, order.marketType);
	const marketSymbol = uiMarket.marketName;
	const isPredictionMarket = uiMarket.isPredictionMarket;
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket,
			isSellSide: ENUM_UTILS.match(order.direction, PositionDirection.SHORT),
		})
	);

	const triggerPriceToUse =
		isSellPredictionMarket && order.triggerPrice.gtZero()
			? MAX_PREDICTION_PRICE_BIGNUM.sub(order.triggerPrice)
			: order.triggerPrice;
	const limitPriceToUse = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(orderLimitPrice)
		: orderLimitPrice;

	if (isMobile) {
		return (
			<TableV2.ResponsiveTableRow
				key={order.orderId.toString()}
				direction={order.direction}
				marketString={
					isPredictionMarket
						? UI_UTILS.getPerpMarketDisplayName(order.marketIndex)
						: marketSymbol
				}
				isDataRow
				className={twMerge('whitespace-nowrap m-0')}
				action={
					<div className="flex flex-row">
						<Button.Secondary
							className="justify-start mr-2"
							size="SMALL"
							onClick={() => handleShowEditOrderModal()}
						>
							<Edit size={20} />
						</Button.Secondary>
						<Button.Secondary
							className="flex items-center justify-between border-none font-display"
							size="SMALL"
							negativeRed
							onClick={() => {
								actions.cancelOrder(order.orderId);
							}}
						>
							<Close size={20} />
						</Button.Secondary>
					</div>
				}
				useSpotWording={uiMarket.isSpot}
				isPredictionMarket={isPredictionMarket}
			>
				<TableV2.CardValue
					className="font-numeral"
					label="Order Type"
					value={COMMON_UI_UTILS.getOrderLabelFromOrderDetails(order)}
				/>

				<TableV2.CardValue
					className="font-numeral "
					label="Filled / Size"
					value={
						<>
							{showAccountValues
								? COMMON_UI_UTILS.trimTrailingZeros(
										UI_UTILS.roundToStepSizeIfLargeEnough(
											order.baseAssetAmountFilled.toTradePrecision(),
											stepSize
										)
								  )
								: '∗∗∗∗∗'}{' '}
							/{' '}
							{showAccountValues
								? COMMON_UI_UTILS.trimTrailingZeros(
										UI_UTILS.roundToStepSizeIfLargeEnough(
											order.baseAssetAmount.toTradePrecision(),
											stepSize
										)
								  )
								: '∗∗∗∗∗'}
						</>
					}
				/>

				<TableV2.CardValue
					className="font-numeral"
					label="Trigger / Limit"
					value={
						<>
							{triggerPriceToUse.eqZero()
								? '-'
								: triggerPriceToUse.toNotional(true)}{' '}
							<span className="text-text-secondary">/</span>{' '}
							{order.oraclePriceOffset ? (
								<Tooltip
									content={
										<span>
											{`Oracle ${
												order.oraclePriceOffset.gtZero() ? '+' : ''
											}${order.oraclePriceOffset.print()}`}
										</span>
									}
								>
									{limitPriceToUse.toNotional(true)}
								</Tooltip>
							) : (
								<span>
									{limitPriceToUse.eqZero()
										? '-'
										: limitPriceToUse.toNotional(true)}
								</span>
							)}
						</>
					}
				/>

				<TableV2.CardValue
					className="font-numeral"
					label="Flags"
					value={
						<>
							{order.reduceOnly && <Text.MICRO1>Reduce Only</Text.MICRO1>}
							{order.postOnly && <Text.MICRO1>Post Only</Text.MICRO1>}
							{!order.reduceOnly && !order.postOnly && '-'}
						</>
					}
				/>
			</TableV2.ResponsiveTableRow>
		);
	} else {
		return (
			<>
				<TableV2.BodyRow
					key={order.orderId.toString()}
					grid={tableGrid}
					isDataRow
				>
					{isPredictionMarket ? (
						<TableV2.OpenPredictionPositionIdCell
							marketIndex={order.marketIndex}
							direction={
								matchEnum(order.direction, PositionDirection.LONG)
									? 'long'
									: 'short'
							}
							title={PredictionMarketConfigs.get(order.marketIndex).title}
						/>
					) : (
						<TableV2.MarketCell
							className="font-numeral"
							marketSymbol={marketSymbol}
							onClick={() =>
								handleClickMarket(order.marketIndex, order.marketType)
							}
							direction={
								matchEnum(order.marketType, MarketType.PERP)
									? matchEnum(order.direction, PositionDirection.LONG)
										? 'long'
										: 'short'
									: matchEnum(order.direction, PositionDirection.LONG)
									? 'buy'
									: 'sell'
							}
						/>
					)}

					<TableV2.BodyCell className="uppercase font-numeral">
						{COMMON_UI_UTILS.getOrderLabelFromOrderDetails(order)}
					</TableV2.BodyCell>

					<TableV2.BodyCell
						className="uppercase font-numeral"
						innerClassName="flex space-x-1"
					>
						<NumberDisplayV3
							value={order.baseAssetAmountFilled}
							formattingProps={{
								displayType: 'asset',
								toPrecision: 6,
								trimZeroes: true,
							}}
							displayProps={{
								isAccountValueToHide: true,
							}}
						/>
						<span className="text-text-secondary">/</span>
						<NumberDisplayV3
							value={order.baseAssetAmount}
							formattingProps={{
								displayType: 'asset',
								toPrecision: 6,
								trimZeroes: true,
							}}
							displayProps={{
								isAccountValueToHide: true,
							}}
						/>
					</TableV2.BodyCell>

					<TableV2.BodyCell
						className="text-left uppercase font-numeral"
						innerClassName="flex space-x-1"
					>
						{triggerPriceToUse.eqZero() ? (
							<span>-</span>
						) : (
							<NumberDisplayV3
								value={triggerPriceToUse}
								formattingProps={{
									displayType: 'price',
									toPrecision: 6,
									trimZeroes: true,
								}}
							/>
						)}
						<span className="text-text-secondary">/</span>
						{order.oraclePriceOffset ? (
							<Tooltip
								content={
									<span>
										{`Oracle ${
											order.oraclePriceOffset.gtZero() ? '+' : ''
										}${order.oraclePriceOffset.print()}`}
									</span>
								}
							>
								<NumberDisplayV3
									value={limitPriceToUse}
									formattingProps={{
										displayType: 'price',
										toPrecision: 6,
										trimZeroes: true,
									}}
								/>
							</Tooltip>
						) : (
							<span>
								{limitPriceToUse.eqZero() ? (
									<span>-</span>
								) : (
									<NumberDisplayV3
										value={limitPriceToUse}
										formattingProps={{
											displayType: 'price',
											toPrecision: 6,
											trimZeroes: true,
										}}
									/>
								)}
							</span>
						)}
					</TableV2.BodyCell>

					<TableV2.BodyCell className="uppercase font-numeral">
						<div className="flex flex-col w-full gap-1">
							{order.reduceOnly && <Text.MICRO1>Reduce Only</Text.MICRO1>}
							{order.postOnly && <Text.MICRO1>Post Only</Text.MICRO1>}
							{!order.reduceOnly && !order.postOnly && (
								<TableV2.EmptyValueFiller />
							)}
						</div>
					</TableV2.BodyCell>

					<TableV2.BodyCell>
						<div className="flex flex-row">
							<Button.Secondary
								className="justify-start mr-2"
								size="SMALL"
								id={`order_edit_button_${order.orderId.toString()}`}
								onClick={() => setShowEditOrderPopup(true)}
								ref={refs.setReference}
								{...getReferenceProps()}
							>
								<Edit />
							</Button.Secondary>
							<Button.Secondary
								size="SMALL"
								className={`flex items-center justify-between border-none px-1 py-1 font-display`}
								negativeRed
								onClick={() => {
									actions.cancelOrder(order.orderId);
								}}
							>
								<Close size={20} />
							</Button.Secondary>
						</div>
					</TableV2.BodyCell>

					{showFillButton &&
						ENUM_UTILS.match(order.marketType, MarketType.PERP) && (
							<TableV2.BodyCell>
								<div className="w-full">
									<TableActionButton
										className="justify-start px-0"
										positive
										label="Fill"
										handleClick={() => {
											actions.fillOrder(
												order.orderId,
												order.marketIndex,
												order.direction
											);
										}}
									/>
								</div>
							</TableV2.BodyCell>
						)}
				</TableV2.BodyRow>

				{showEditOrderPopup && (
					<EditOrderPopup
						existingOrder={order}
						onClose={() => setShowEditOrderPopup(false)}
						setFloating={refs.setFloating}
						getFloatingProps={getFloatingProps}
						floatingStyles={floatingStyles}
					/>
				)}
			</>
		);
	}
};

const OpenLpOrderRow = ({
	lpOrder,
	index,
	tableGrid,
	showFillButton,
	showAccountValues,
	showLpModal,
}: {
	lpOrder: AccountLPOrder;
	index: number;
	tableGrid: string;
	showFillButton: boolean;
	showAccountValues: boolean;
	showLpModal: (
		marketIndex: number,
		modalStr: 'showAddLiquidityModal' | 'showRemoveLiquidityModal'
	) => void;
}) => {
	const isMobile = useIsMobileScreenSize();
	const uiMarket = UIMarket.createPerpMarket(lpOrder.marketIndex);

	const marketSymbol = uiMarket.marketName;

	if (isMobile) {
		return (
			<TableV2.ResponsiveTableRow
				key={`lp_order_${lpOrder.marketIndex}`}
				direction={PositionDirection.LONG}
				marketString={UI_UTILS.getPerpMarketDisplayName(lpOrder.marketIndex)}
				isDataRow
				className={twMerge('whitespace-nowrap m-0')}
				action={
					<div className="flex flex-row">
						<Button.Secondary
							className="justify-start mr-2"
							size="SMALL"
							onClick={() => {
								showLpModal(lpOrder.marketIndex, 'showAddLiquidityModal');
							}}
						>
							<Edit size={20} />
						</Button.Secondary>
						<Button.Secondary
							className="flex items-center justify-between border-none font-display"
							size="SMALL"
							negativeRed
							onClick={() => {
								showLpModal(lpOrder.marketIndex, 'showRemoveLiquidityModal');
							}}
						>
							<Close size={20} />
						</Button.Secondary>
					</div>
				}
				useSpotWording={uiMarket.isSpot}
				isPredictionMarket={uiMarket.isPredictionMarket}
			>
				<TableV2.CardValue
					className="font-numeral"
					label="Order Type"
					value={'BAL'}
				/>
				<TableV2.CardValue
					className="font-numeral"
					label="Size"
					value={
						<>
							{showAccountValues
								? `${lpOrder.baseAmountLong.abs().printShort(true)} LONG`
								: '∗∗∗∗∗ LONG'}{' '}
							/ <br />
							{showAccountValues
								? `${lpOrder.baseAmountShort.abs().printShort(true)} SHORT`
								: '∗∗∗∗∗ SHORT'}
						</>
					}
				/>

				<TableV2.CardValue
					className="font-numeral"
					label="Trigger / Limit"
					value="-"
				/>

				<TableV2.CardValue className="font-numeral" label="Flags" value="-" />
			</TableV2.ResponsiveTableRow>
		);
	} else {
		return (
			<TableV2.BodyRow
				key={`lp_order_${lpOrder.marketIndex}`}
				grid={tableGrid}
				isDataRow
			>
				{uiMarket.isPredictionMarket ? (
					<TableV2.OpenPredictionPositionIdCell
						marketIndex={uiMarket.marketIndex}
						direction={'long'}
						isLpOrder={true}
						title={PredictionMarketConfigs.get(uiMarket.marketIndex).title}
					/>
				) : (
					<TableV2.MarketCell
						key={`market_${index}`}
						marketSymbol={marketSymbol}
						className="uppercase font-numeral"
						isSpot={false}
					/>
				)}

				<TableV2.BodyCell className="uppercase font-numeral">
					BAL
				</TableV2.BodyCell>

				<TableV2.BodyCell className="uppercase font-numeral">
					{showAccountValues
						? `${lpOrder.baseAmountLong.abs().printShort(true)} LONG`
						: '∗∗∗∗∗ LONG'}{' '}
					/{' '}
					{showAccountValues
						? `${lpOrder.baseAmountShort.abs().printShort(true)} SHORT`
						: '∗∗∗∗∗ SHORT'}
				</TableV2.BodyCell>

				<TableV2.BodyCell className="text-left uppercase font-numeral">
					-
				</TableV2.BodyCell>

				<TableV2.BodyCell className="uppercase font-numeral">
					-
				</TableV2.BodyCell>

				<TableV2.BodyCell>
					<div className="flex flex-row">
						<Button.Secondary
							className="justify-start mr-2"
							size="SMALL"
							id={`add_lp_button_${lpOrder.marketIndex}`}
							onClick={() => {
								showLpModal(lpOrder.marketIndex, 'showAddLiquidityModal');
							}}
						>
							<Edit />
						</Button.Secondary>
						<Button.Secondary
							size="SMALL"
							id={`remove_lp_button_${lpOrder.marketIndex}`}
							className={`flex items-center justify-between border-none px-1 py-1 font-display`}
							negativeRed
							onClick={() => {
								showLpModal(lpOrder.marketIndex, 'showRemoveLiquidityModal');
							}}
						>
							<Close size={20} />
						</Button.Secondary>
					</div>
				</TableV2.BodyCell>

				{showFillButton && <TableV2.BodyCell>-</TableV2.BodyCell>}
			</TableV2.BodyRow>
		);
	}
};

const OpenOrdersTable = (props: {
	overviewPage?: boolean;
	predictionsPage?: boolean;
}) => {
	const [isTableScrollable, setIsTableScrollable] = useState(false);

	const {
		showOnlyCurrentMarket,
		showPerpMarkets,
		showSpotMarkets,
		showPredictionMarkets,
		setShowPerpMarkets,
		setShowSpotMarkets,
		setShowPredictionMarkets,
		setShowOnlyCurrentMarket,
	} = useTableFiltersState(props);

	const showFillButton = useDevSwitchIsOn();
	const tableGrid = `minmax(130px,10fr) minmax(85px,11fr) minmax(120px,11fr) minmax(120px,11fr) minmax(100px,8fr) minmax(100px,7fr) ${
		showFillButton ? 'minmax(85px,5fr)' : ''
	}`;
	const currentAccount = useTickedAccountData();
	const actions = useDriftActions();
	const isMobile = useIsMobileScreenSize();
	const showAccountValues = useShowAccountValues();

	const getOraclePrice = useGetOraclePriceForMarket();

	const openOrders: AccountOrder[] =
		currentAccount?.openOrders ?? EMPTY_ORDERS_STATE;
	const openLpOrders: AccountLPOrder[] =
		currentAccount?.openLpOrders ?? EMPTY_ORDERS_STATE;

	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);

	const [displayOrders, setDisplayOrders] = useState<AccountOrder[]>(undefined);
	const [filteredDisplayOrders, setFilteredDisplayOrders] =
		useState<AccountOrder[]>(undefined);
	const [displayLpOrders, setDisplayLpOrders] =
		useState<AccountLPOrder[]>(undefined);
	const [filteredDisplayLpOrders, setFilteredDisplayLpOrders] =
		useState<AccountLPOrder[]>(undefined);

	const filterOrders = (
		orders: AccountOrder[],
		orderFiltersOptions: PositionsAndOrdersFilterOption[],
		showOnlyCurrentMarket: boolean,
		selectedMarket: UIMarket
	) => {
		const anyFiltersApplied = orderFiltersOptions.reduce(
			(acc, option) => acc || option.value,
			false
		);

		if (!anyFiltersApplied) return orders;

		if (showOnlyCurrentMarket) {
			return orders.filter(
				(order) =>
					order.marketIndex === selectedMarket.market.marketIndex &&
					ENUM_UTILS.match(order.marketType, selectedMarket.marketType)
			);
		}

		return orders.filter((order) => {
			const uiMarket = new UIMarket(order.marketIndex, order.marketType);

			return (
				(showPerpMarkets && uiMarket.isPerp && !uiMarket.isPredictionMarket) ||
				(showSpotMarkets && uiMarket.isSpot) ||
				(showPredictionMarkets && uiMarket.isPredictionMarket)
			);
		});
	};

	const orderFiltersOptions: PositionsAndOrdersFilterOption[] = [
		{
			type: 'perpMarkets',
			label: 'Perp Orders',
			value: showOnlyCurrentMarket ? false : showPerpMarkets,
		},
		{
			type: 'spotMarkets',
			label: 'Spot Orders',
			value: showOnlyCurrentMarket ? false : showSpotMarkets,
		},
		{
			type: 'predictionMarkets',
			label: 'BET Orders',
			value: showOnlyCurrentMarket ? false : showPredictionMarkets,
		},
	];

	if (!props.overviewPage) {
		orderFiltersOptions.push({
			type: 'selectedMarketOnly',
			label: 'Only selected market',
			value: showOnlyCurrentMarket,
			// disabled: false,
		});
	}

	const anyFiltersApplied = orderFiltersOptions.reduce(
		(acc, option) => acc || option.value,
		false
	);

	useEffect(() => {
		if (!openOrders) return;

		let tempOrders = openOrders;

		const ordersForMarket = openOrders.filter(
			(order) =>
				order.marketIndex === selectedMarket.market.marketIndex &&
				ENUM_UTILS.toStr(order.marketType) == selectedMarket.marketType
		);

		if (ordersForMarket.length > 0) {
			tempOrders = ordersForMarket.concat(
				showOnlyCurrentMarket
					? []
					: openOrders.filter(
							(order) =>
								order.marketIndex !== selectedMarket.market.marketIndex ||
								ENUM_UTILS.toStr(order.marketType) !== selectedMarket.marketType
					  )
			);
		}

		const displayOrders = [...tempOrders].sort(
			(orderA, orderB) => orderB.slot.toNumber() - orderA.slot.toNumber()
		);

		setDisplayOrders(displayOrders);
		const filteredOrders = filterOrders(
			displayOrders,
			orderFiltersOptions,
			showOnlyCurrentMarket,
			selectedMarket
		);
		setFilteredDisplayOrders(filteredOrders);
	}, [
		openOrders,
		selectedMarket?.market?.symbol,
		showOnlyCurrentMarket,
		showPerpMarkets,
		showSpotMarkets,
		showPredictionMarkets,
		anyFiltersApplied,
	]);

	useEffect(() => {
		if (!openLpOrders) return;

		let tempLpOrders = openLpOrders;

		const lpOrdersForMarket = openLpOrders.filter(
			(order) =>
				order.marketIndex === selectedMarket.market.marketIndex &&
				ENUM_UTILS.match(selectedMarket.marketType, MarketType.PERP)
		);

		if (lpOrdersForMarket.length > 0) {
			tempLpOrders = lpOrdersForMarket.concat(
				openLpOrders.filter(
					(order) => order.marketIndex !== selectedMarket.market.marketIndex
				)
			);
		}

		setDisplayLpOrders(tempLpOrders);
		setFilteredDisplayLpOrders(
			!anyFiltersApplied
				? tempLpOrders
				: showOnlyCurrentMarket
				? tempLpOrders.filter(
						(order) => order.marketIndex === selectedMarket.market.marketIndex
				  )
				: tempLpOrders.filter((order) => {
						const uiMarket = new UIMarket(order.marketIndex, MarketType.PERP);

						return (
							(showPerpMarkets &&
								uiMarket.isPerp &&
								!uiMarket.isPredictionMarket) ||
							(showSpotMarkets && uiMarket.isSpot) ||
							(showPredictionMarkets && uiMarket.isPredictionMarket)
						);
				  })
		);
	}, [
		openLpOrders,
		selectedMarket?.market?.symbol,
		showOnlyCurrentMarket,
		showPerpMarkets,
		showSpotMarkets,
		showPredictionMarkets,
		anyFiltersApplied,
	]);

	const headers = [
		'market',
		'type',
		'size',
		'trigger / limit',
		'flags',
		'action',
	];

	const handleCancelAllOrCurrentOrders = useCallback(() => {
		actions.cancelAllOrders({
			orderIdsToCancel:
				filteredDisplayOrders?.map((order) => order.orderId) || [],
		});
	}, [
		showOnlyCurrentMarket,
		props.predictionsPage,
		props.overviewPage,
		filteredDisplayOrders,
	]);

	const handlechangeFilters = useCallback(
		(filterOption: PositionsAndOrdersFilterOption) => {
			switch (filterOption.type) {
				case 'perpMarkets':
					setShowPerpMarkets(filterOption.value);
					break;
				case 'spotMarkets':
					setShowSpotMarkets(filterOption.value);
					break;
				case 'predictionMarkets':
					setShowPredictionMarkets(filterOption.value);
					break;
				case 'selectedMarketOnly':
					setShowOnlyCurrentMarket(filterOption.value);
					break;
			}
		},
		[setShowPerpMarkets, setShowPredictionMarkets]
	);

	const showLpModal = useCallback(
		(
			marketIndex: number,
			modalStr: 'showAddLiquidityModal' | 'showRemoveLiquidityModal'
		) => {
			actions.switchMarket({
				marketIndex,
				marketType: MarketType.PERP,
			});
			actions.showModal(modalStr);
		},
		[]
	);

	const getOrderLimitPrice = (order: AccountOrder): BigNum => {
		const marketId = isVariant(order.marketType, 'perp')
			? MarketId.createPerpMarket(order.marketIndex)
			: MarketId.createSpotMarket(order.marketIndex);

		return COMMON_UI_UTILS.getLimitPriceFromOracleOffset(
			order,
			getOraclePrice(marketId)
		);
	};

	const tableStateRecords =
		!displayOrders && !displayLpOrders
			? undefined
			: (displayOrders ?? ([] as any[])).concat(
					displayLpOrders ?? ([] as any[])
			  );

	const hiddenOrdersCount =
		(openOrders?.length || 0) +
		(openLpOrders?.length || 0) -
		(filteredDisplayLpOrders?.length || 0) +
		(filteredDisplayOrders?.length || 0);

	const allMarketsControls = (
		<div className={`items-center w-full flex justify-between p-1`}>
			<PositionsAndOrdersFilterDropdown
				filterOptions={orderFiltersOptions}
				onChange={handlechangeFilters}
				labelHelperText={
					hiddenOrdersCount === 0
						? 'Showing all orders'
						: `Showing ${
								(filteredDisplayLpOrders?.length || 0) +
								(filteredDisplayOrders?.length || 0)
						  }/${
								(openOrders?.length || 0) + (openLpOrders?.length || 0)
						  } orders`
				}
			/>

			<Button.Secondary
				size="SMALL"
				disabled={!filteredDisplayOrders || !filteredDisplayOrders.length}
				onClick={handleCancelAllOrCurrentOrders}
				negativeRed
				className="whitespace-nowrap"
			>
				{`Cancel ${filteredDisplayOrders?.length || 0} Order${
					filteredDisplayOrders?.length === 1 ? '' : 's'
				}`}
			</Button.Secondary>
		</div>
	);

	const tableContent = (
		<TableStateWrapper
			requireAccountCreated
			records={tableStateRecords}
			emptyStateText={
				props.predictionsPage
					? 'No open BET orders found'
					: 'No open orders found'
			}
			requireWalletConnect
			id="open_orders_table"
		>
			<TableV2.Skeleton
				noBorder
				setIsScrollable={setIsTableScrollable}
				top={
					isMobile ? (
						<div className="pb-3 border-container-border bg-container-bg">
							{allMarketsControls}
						</div>
					) : (
						<TableV2.HeaderRow
							grid={tableGrid}
							forceBottomBorder
							header
							addScrollPadding={isTableScrollable}
						>
							{headers.map((label) => (
								<TableV2.HeaderCell
									className={`${
										props.overviewPage ? 'bg-main-bg' : ''
									} capitalize`}
									key={`header_${label}`}
								>
									{label}
								</TableV2.HeaderCell>
							))}
						</TableV2.HeaderRow>
					)
				}
				middle={
					<div className={`sm:max-h-full w-full text-xs`}>
						{filteredDisplayOrders?.map((order) => {
							const orderLimitPrice = getOrderLimitPrice(order);

							return (
								<OpenPerpOrderRow
									key={order.orderId}
									tableGrid={tableGrid}
									order={order}
									isOverviewPage={props.overviewPage}
									showFillButton={showFillButton}
									showAccountValues={showAccountValues}
									orderLimitPrice={orderLimitPrice}
								/>
							);
						})}

						{filteredDisplayLpOrders?.map((lpOrder, index) => (
							<OpenLpOrderRow
								key={lpOrder.marketIndex}
								lpOrder={lpOrder}
								index={index}
								tableGrid={tableGrid}
								showFillButton={showFillButton}
								showAccountValues={showAccountValues}
								showLpModal={showLpModal}
							/>
						))}
					</div>
				}
				bottom={
					isMobile ? null : (
						<TableV2.SummaryRow
							className={'border-t border-container-border'}
							grid={''}
						>
							{allMarketsControls}
						</TableV2.SummaryRow>
					)
				}
			/>
		</TableStateWrapper>
	);

	return isMobile ? (
		<div className={props.overviewPage ? 'mb-4' : 'p-4'}>{tableContent}</div>
	) : (
		<>{tableContent}</>
	);
};

export default OpenOrdersTable;
