'use client';

import React from 'react';
import Button from '../Button';
import CheckboxInput from '../CheckboxInput';
import PopoverWrapper from '../PopoverWrapper';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import Text from '../Text/Text';
import { Filter } from '@drift-labs/icons';

export type PositionsAndOrdersFilterOption = {
	type:
		| 'perpMarkets'
		| 'predictionMarkets'
		| 'spotMarkets'
		| 'selectedMarketOnly';
	label: string;
	value: boolean;
	disabled?: boolean;
};

const PositionsAndOrdersFilterDropdown = ({
	filterOptions,
	onChange,
	labelHelperText,
}: {
	filterOptions: PositionsAndOrdersFilterOption[];
	onChange: (updatedOption: PositionsAndOrdersFilterOption) => void;
	buttonHelperText?: string;
	labelHelperText?: string | React.ReactNode;
}) => {
	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			placement: 'top',
			strategy: 'fixed',
		},
		{
			disableAutoPlacement: false,
		}
	);

	const toggleExpanded = () => setIsPopoverOpen(!isPopoverOpen);

	const toggleFilterOption = (option: PositionsAndOrdersFilterOption) => {
		onChange({
			...option,
			value: !option.value,
		});
	};

	return (
		<>
			<div className="flex flex-row items-center pr-3">
				<Button.Secondary
					size="SMALL"
					onClick={toggleExpanded}
					className="whitespace-nowrap flex flex-row items-center"
					ref={refs.setReference}
					{...getReferenceProps()}
				>
					<Filter />{' '}
					<Text.BODY2>
						{filterOptions.reduce(
							(acc, option) => acc + (option.value ? 1 : 0),
							0
						)}
					</Text.BODY2>
				</Button.Secondary>

				{labelHelperText && (
					<Text.BODY2 className="text-text-label ml-3">
						{labelHelperText}
					</Text.BODY2>
				)}
			</div>
			{isPopoverOpen && (
				<PopoverWrapper
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps()}
				>
					<div className="p-1">
						{filterOptions.map((option) => {
							return (
								<div
									className="mx-3 py-3 border-b border-container-border last:border-none"
									key={option.type}
								>
									<CheckboxInput
										checked={option.value}
										onChange={() => {
											toggleFilterOption(option);
										}}
										label={option.label}
										className="pr-4 text-text-default"
										secondaryStyle
										disabled={option.disabled}
									/>
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</>
	);
};

export default PositionsAndOrdersFilterDropdown;
